














































import { User } from '@/models';
import { defineComponent, reactive, ref } from '@/plugins/composition';
import { rules } from '@/domains/practitioner/modules/assessments/composables/validations';
import { useFormValidation } from '@/composables/useFormValidation';
import { useLoader } from '@/composables/useLoader';

export default defineComponent({
  name: 'ForgotPasswordPage',
  setup() {
    const formData = reactive({
      email: '',
    });

    const valid = ref(false);
    const { loading, withLoader } = useLoader();
    const { errors, mapErrors } = useFormValidation(formData);
    const success = ref(false);

    const submit = async () =>
      withLoader(async () => {
        const response = await User.forgotPassword(formData.email).catch((error) => {
          mapErrors(error);
        });

        if (response) {
          success.value = true;
        }
      });

    return {
      appLogo: require('@/assets/logo.png'),

      rules,
      formData,
      submit,
      loading,
      valid,
      errors,
      success,
    };
  },
});
